html {
    overflow: hidden;
    position: fixed;
    width: 100%;
}
body {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    color: black;
}
h2 {
    text-align: center;
}
a {
    text-decoration: none;
    color: white;
}
a:hover {
    text-decoration: none;
    color: white;
}


.flex-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.flex-container .content {
    flex: 0 1 auto;
    overflow-y: hidden;
    overflow-x: hidden;
}
.flex-container .footer {
    flex: 0 1 auto;
    background-color: #EEE;
    text-align: center;
    padding: 1em;
}
.flex-container .expand {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}


.center {
    text-align: center;
    margin: 0 auto;
}
.scroll {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.wrapper {
    position: relative;
}


.video {
    position: absolute;
    top: 0;
}
.video.fadeIn {
    opacity:1;
    transition: opacity 0.5s;
}
.video.fadeOut {
    opacity:0;
    transition: opacity 0.5s;
}


.feedback {
    position: absolute;
    top: 5;
    left: 5;
}
.icon {
    position: absolute;
    font-size: 1.5rem;
    transition: opacity 0.5s ease-in-out;
}
.filled {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.icon.green {
    left: 0rem;
}
.icon.yellow {
    left: 2rem;
}
.icon.red {
    left: 4rem;
}


.visible {
    opacity: 1;
}
.invisible {
    opacity: 0;
}
.hidden {
    display: none;
    opacity: 0;
}
.showing {
    display: block;
    opacity: 1;
}


.subtitle-container {
    position: relative;
    transition: top 0.5s, color 0.5s, box-shadow 0.5s ease-in-out;
}
.subtitle {
    padding: 1em;
    transition: height 0.5s;
}
.idle {
    color: white;
    box-shadow: inset 0 0 0 3000px rgba(0,0,0,0.25);
}
.playing {
    color: black;
    box-shadow: inset 0 0 0 3000px rgba(0,0,0,0);
}
.arrow {
    position: absolute;
    left: 50%;
    top: 0;
    color: white;
    transition: transform 0.5s, top 0.5s ease-in-out;
}


.options {
    position: relative;
    transition: top 0.5s;
}
.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
}